<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="List" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('market.activitycommon/add')" addName="新增" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.name" size="small" clearable placeholder="按名称查询">
				</el-input>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="cfg.operator_name" title="第三方名称" />
			<vxe-table-column slot="table-item" field="name" title="活动名称" />
			<vxe-table-column slot="table-item" title="活动时间" width="200">
				<template v-slot="{ row }">
					<span>{{ row.btime }} ~ {{ row.etime }}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="status" title="状态">
				<template v-slot="{ row }">
					<span>{{row.status==1?'启用':'停用'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="cfg.cuser" title="创建人" />
			<vxe-table-column slot="table-item" field="cfg.ctime" title="创建时间" />
			<vxe-table-column slot="table-item" field="cfg.stime" title="停用时间" />
			<vxe-table-column slot="table-item" title="操作" width="300">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="detail(row)">详情</el-button>
					<el-button size="small" plain @click="stopOne(row)">{{ row.status==1?"停用":"启用" }}</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 编辑 -->
		<el-dialog title="新增" :visible.sync="editDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="700px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<div style="font-size: 20px;font-weight: bold;margin-bottom: 20px;">活动信息</div>
				<el-form-item label="活动名称" prop="name">
					<el-input v-model="editDialogFormData.name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="活动时间" prop="btime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.btime" type="date" value-format="yyyy-MM-dd" placeholder="开启时间"></el-date-picker> - <el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.etime" type="date" value-format="yyyy-MM-dd" placeholder="结束时间"></el-date-picker>
				</el-form-item>				
				<el-form-item label="第三方" prop="OperatorID">
					<el-select v-model="editDialogFormData.cfg.OperatorID" size="small" clearable style="width: 100px" placeholder="企业名称">
						<el-option v-for="(item,index) in OperatorList" :key="index" :label="item.operator_name" :value="item.OperatorID"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="活动描述" prop="description">
					<el-input type="textarea" v-model="editDialogFormData.cfg.description" ></el-input>
				</el-form-item>
				<div style="font-size: 20px;font-weight: bold;margin-bottom: 20px;">折扣信息</div>
				<el-form-item label="推送价格" prop="price_list">
					<el-button class="add-button" icon="el-icon-plus" type="primary" size="mini" @click="priceAdd">增加</el-button><br/>
					<template v-for="(item,idx) in editDialogFormData.cfg.price_list">
						<el-input-number type="number" :min="0" :max="24" :step="1" :precision="0" v-model="editDialogFormData.cfg.price_list[idx][0]" style="width: 98px;" class="input" size="small"></el-input-number>
						<el-input-number type="number" :min="0" :max="24" :step="1" :precision="0" v-model="editDialogFormData.cfg.price_list[idx][1]" style="width: 98px;" class="input" size="small"></el-input-number>
						电费 <el-input v-model="editDialogFormData.cfg.price_list[idx][2]" size="mini" style="width: 100px;"></el-input>
						服务费 <el-input v-model="editDialogFormData.cfg.price_list[idx][3]" size="mini" style="width: 100px;"></el-input>
						<el-button v-if="idx > 0" class="del-button" size="mini" type="danger" icon="el-icon-delete" @click="priceDel(idx)"></el-button> 
						<br/>
					</template>
				</el-form-item>
				<el-form-item label="参与电站" prop="group_ids">
					<el-select v-model="editDialogFormData.cfg.group_ids" multiple filterable placeholder="参与电站">
						<el-option v-for="(item,index) in GroupList" :key="index" :label="item.group_name" :value="item.group_id"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消</el-button>
			</div>
		</el-dialog>

		<!-- 详情 -->
		<el-dialog title="详情" :visible.sync="viewShow" :close-on-click-modal="false" :close-on-press-escape="false" width="700px">
			<el-descriptions title="活动信息" :column="1">
				<el-descriptions-item label="活动名称">
					{{ viewData.name }}
				</el-descriptions-item>
				<el-descriptions-item label="活动时间">
					{{ viewData.btime }} ~ {{ viewData.etime }}
				</el-descriptions-item>				
				<el-descriptions-item label="第三方">
					{{ viewData.cfg.operator_name }}
				</el-descriptions-item>
				<el-descriptions-item label="活动描述">
					{{ viewData.cfg.description }}
				</el-descriptions-item>				
			</el-descriptions>
			<el-descriptions title="折扣信息" :column="1">
				<el-descriptions-item v-for="(item,idx) in viewData.cfg.price_list" :key="idx" :label="item[0]+'-'+item[1]">
					电费:{{ item[2] }} 服务费:{{ item[3] }}
				</el-descriptions-item>
				<el-descriptions-item label="参与电站">
					{{ viewData.group_names }}
				</el-descriptions-item>
			</el-descriptions>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import upload from '@/components/upload'

	export default {
		name: 'hlht-activity',
		components: {
			Tables,
			upload
		},
		data() {
			return {
				// 表格信息
				tableName: '活动折扣',
				isLoading: false,
				totalPage: 0,
				searchFormData: {},
				List: [],
				GroupList: [], //场站列表
				OperatorList: [], //组织列表
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'change'
					}],
					btime: [{
						required: true,
						message: '请输入名称',
						trigger: 'change'
					}],
					OperatorID: [{
						required: true,
						message: '请选择第三方',
						trigger: 'change',
						validator: (rule, value, callback)=>{
							if(!("OperatorID" in this.editDialogFormData.cfg)) return callback(new Error('请选择第三方'));
							callback();
						}
					}],
					price_list: [{
						required: true,
						message: '必须输入推送价格',
						trigger: 'change',
						validator: (rule, value, callback)=>{
							if(this.editDialogFormData.cfg.price_list.length < 1) return callback(new Error('推送价格错误'));
							let h = 0;
							for(let hl of this.editDialogFormData.cfg.price_list){
								if(h > hl[0]) return callback(new Error('推送价格错误'));
								if(hl[0] > hl[1]) return callback(new Error('推送价格错误'));
								h = hl[1];
							}
							callback();
						}
					}],
					group_ids: [{
						required: true,
						message: '必须选择站点',
						trigger: 'change',
						validator: (rule, value, callback)=>{
							if(!(this.editDialogFormData.cfg.group_ids.length > 0)) return callback(new Error('请选择区域'));
							callback();
						}
					}]
				},
				// 编辑
				editDialogShow: false,
				editDialogFormData: {cfg:{price_list:[]}},
				viewShow: false,
				viewData: {cfg:{price_list:[]}}
			}
		},
		computed: {
		},
		mounted() {
			this.getList();

			this.$api.Hlht.Manager.operatorlist({token: this.$store.state.user.token}).then((res)=>{
				this.OperatorList = res;
			});
			this.$api.Device.GetGroupMinList({token: this.$store.state.user.token}).then((res)=>{
				this.GroupList = res;
			});
		},
		activated() {},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					tp: 7,
					page: currentPage,
					size: pageSize
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Market.ActivityCommon.list(params);
				for(let v of res.data){
					v.cfg = JSON.parse(v.cfg);
					if(v.cfg.ele_price){
						v.cfg.price_list = [[0, 24, v.cfg.ele_price, v.cfg.service_price]];
					}
					v.btime = v.btime.substr(0,10);
					v.etime = v.etime.substr(0,10);
				} 
				this.List = res.data;
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 重置form
			resetFormData() {
				this.editDialogFormData = {cfg:{price_list:[[0,24,0.8,0.2]]}}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 新增
			addOne() {
				this.resetFormData()
				this.editDialogShow = true
			},
			// 编辑
			async editOne(row) {
				this.resetFormData();
				this.editDialogFormData = Object.assign({}, row);
				delete this.editDialogFormData._XID;
				this.editDialogShow = true
			},
			stopOne(row){ //停用活动
				this.editDialogFormData = row;
				delete this.editDialogFormData._XID;
				if(this.editDialogFormData.status == 1){
					this.editDialogFormData.status = 0;
					let dt = new Date();
					this.editDialogFormData.cfg.stime = dt.getFullYear()+"-"+(dt.getMonth()+1)+"-"+dt.getDate()+" "+dt.getHours().toString().padStart(2,'0')+":"+dt.getMinutes().toString().padStart(2,'0')+":"+dt.getSeconds().toString().padStart(2,'0');
				}else{
					this.editDialogFormData.status = 1;
				}
				this.save();
			},
			// 删除
			deleteOne(row) {
				this.$confirm('确定要删除该记录吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						id: row.id
					}
					await this.$api.Market.ActivityCommon.del(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.editDialogShow = false
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate(valid => {
					if (valid) {
						this.save();
					}
				})
			},
			async save(){ //保存数据
				let params = {
					token: this.$store.state.user.token,
					form: Object.assign({"tp":7}, this.editDialogFormData)
				}
				let dt = new Date();
				for(let op of this.OperatorList){
					if(op.OperatorID == params.form.cfg.OperatorID){
						params.form.cfg.operator_name = op.operator_name;
						break;
					}
				}
				if(!(this.editDialogFormData.id > 0)){
					params.form.cfg.cuser = this.$store.state.user.userInfo.realname;
					params.form.cfg.ctime = dt.getFullYear()+"-"+(dt.getMonth()+1)+"-"+dt.getDate()+" "+dt.getHours().toString().padStart(2,'0')+":"+dt.getMinutes().toString().padStart(2,'0')+":"+dt.getSeconds().toString().padStart(2,'0');
				}
				params.form.cfg = JSON.stringify(params.form.cfg);
				params.form = JSON.stringify(params.form);
				if(this.editDialogFormData.id > 0){
					await this.$api.Market.ActivityCommon.edit(params);
				}else{
					await this.$api.Market.ActivityCommon.add(params);
				}
				this.$notify({
					title: '成功',
					message: '操作成功',
					type: 'success'
				})
				this.closeDialog();
				this.$refs.xTable.refreshTable();
			},
			// 返券记录
			detail(row) {
				this.viewData = Object.assign({}, row);
				this.viewData.group_names = "";
				for(let gid of this.viewData.cfg.group_ids){
					for(let gp of this.GroupList){
						if(gp.group_id == gid){
							this.viewData.group_names += gp.group_name + ", ";
						}
					}
				}
				this.viewShow = true;
			},
			//添加价格
			priceAdd(){
				this.editDialogFormData.cfg.price_list.push([0,24,0.8,0.2]);
			},
			//删除价格
			priceDel(idx){
				this.editDialogFormData.cfg.price_list.splice(idx, 1);
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
